import React from 'react'
import { Card, List, Space,  Tooltip } from 'antd';
import pdf from '../../../icons/pdficon.svg'
import { formatIsoToDate, formatIsoToTime } from '../../helpers/utils';

interface IMobileGoRaportProps {
    items : any
    loading: any
  }

export const MobileGoRaport: React.FC<IMobileGoRaportProps> = ({items, loading}) => {

  const titleCard = (name: string) => {
    return (
        <Tooltip trigger="hover" placement="topLeft" title={typeof name === 'string' ? name : ''}>
            {name ? name : '-'}
        </Tooltip>
    )
}

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
        <List pagination={{ position: "both", pageSizeOptions: [25, 50, 75, 100], defaultPageSize: 25 }}
            dataSource = {items}
            loading = {loading}
            renderItem={(element: any) => (
                <Card style={{margin: '15px 0 15px 0'}} key={element.id} title={titleCard(element.name)} size='default'>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Наименование :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.name === 'string' ? element?.name : ''}>
                                    {element?.name ? element?.name.split('').length >= 20 ? `${element?.name.substr(0, 20)}...` : element?.name : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Адрес :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.address === 'string' ? element?.address : ''}>
                                    {element?.address ? element?.address.split('').length >= 20 ? `${element?.address.substr(0, 20)}...` : element?.address : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Время вызова :</div>
                            <div>
                                {element?.timeCall ? formatIsoToDate(element?.timeCall) + ` ` + formatIsoToTime(element?.timeCall) : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>ГБР :</div>
                            <div>
                                {element?.gbr ? element?.gbr : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Причина :</div>
                            <div>
                                {element?.reason ? element?.reason : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Тип события :</div>
                            <div>
                                {element?.eventType ? element?.eventType : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Пульт :</div>
                            <div>
                                {element?.pult ? element?.pult : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>ПЦО :</div>
                            <div>
                                {element?.pco ? element?.pco : '-'}
                            </div>
                        </div>

                </Card>
            )}
            />
    </Space>
  )
}