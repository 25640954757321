import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ReportsStore } from '../../store/reports/Reports'
import { TableFiltersBlock } from '../TableFiltersBlock/TableFiltersBlock'
import { IReportFilters } from '../../store/reports/types'
import { ColumnsType } from 'antd/lib/table'
import { Table, Tooltip } from 'antd'
import { formatIsoToDate, formatIsoToTime } from '../helpers/utils'
import { MobileGoRaport } from './component/MobileGoRaport'
const s = require('./style.module.scss')

interface IGoRaportProps {

}

export const GoRaport: React.FC<IGoRaportProps> = observer(({ }) => {
    const {
        getGoRaport,
        goRaport,
        goRaportLoading
    } = ReportsStore

    const requestHandler = (payload: IReportFilters) => {
        getGoRaport(payload);
    }


    useEffect(() => {
        requestHandler({})
    }, [])

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mobile, setMobile] = useState(windowWidth > 800 ? false : true)

    const scroll: { x?: number | string; y?: number | string } = { x: 1000, y: 700 };
    const defaultTitle = () => 'Выезды (рапорт)';

    interface DataType {
        key: string;
    }

    const columns: ColumnsType<DataType> = goRaport?.headers.map((element: any, index: number) => {
        return ({
            key: element.name,
            title: element.name,
            dataIndex: element.mnemo,
            ellipsis: {
                showTitle: false,
            },
            width: 180,
            render: (address: any) => {
                return (
                    <Tooltip trigger="hover" placement="topLeft" title={typeof address === 'string' ? address : ''}>
                        {typeof address === 'string' ? address : ''}
                    </Tooltip>
                )
            },
        });
    }) || [];


    const dataSource: DataType[] = goRaport?.data
        ? goRaport.data.map((element: any, index: number) => {
            return {
                key: element.id,
                name: element.name,
                address: element.address,
                timeCall: element?.timeCall && formatIsoToDate(element?.timeCall) + ` ` + formatIsoToTime(element?.timeCall),
                gbr: element.gbr,
                reason: element.reason,
                eventType: element.eventType,
                pult: element.pult,
                pco: element.pco,
            };
        })
        : [];
    
        const [activeTable, setActiveTable] = useState<boolean>(false)
    return <div className={s.container}>
        <div className={s.container__header}>
            <TableFiltersBlock flag={'goCountRaport'} xlsxFlag='DailyRaportReport' request={requestHandler} setActiveTable={setActiveTable}  />
        </div>
        <div className={s.container__table}>
            {mobile ? 
            <MobileGoRaport
            items={goRaport?.data}
            loading = {goRaportLoading}/>
            :
            <Table
                size='large'
                rowClassName={s.tableRow}
                columns={columns}
                dataSource={dataSource}
                bordered
                title={defaultTitle}
                scroll={scroll}
                loading={goRaportLoading}
                pagination={{ position: ['topLeft', 'bottomLeft'], pageSizeOptions: [25, 50, 75, 100], defaultPageSize: 25 }}
            />
            }
            
        </div>
    </div>
})