export const ROUTE_PATH = {
    login: '/login',
    goCountRaport: '/goCountRaport',
    goExternalObject: '/goExternalObject',
    goCountGroup: '/goCountGroup',
    goRaport: '/goRaport',
    dislocations: '/dislocations',
    raports: '/raports',
    caesar : '/caesar',
    numberOfDepatures : '/goCountChop',
    raportsVK: '/raportsVKTeams',
    raportsAlert: '/raportsAlert',
    journalActions: '/journalActions',
    raportsMonths: '/raportsMonths',
    operatorRaports: '/operatorRaports',
    settings: '/settings'
}