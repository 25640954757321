import { makeAutoObservable, runInAction } from "mobx";
import { instance, key } from "../api";
import { IRequestAuthData } from "../api/bodies/types";
import { IMyInfo } from "./types";
import { checkError } from "../api/helpers";

export class LoginPage {

    loginLoading: boolean = false
    isAuth: boolean = false
    myInfo: IMyInfo | null = null
    myInfoLoading: boolean = false


    constructor() {
        makeAutoObservable(this)
    }

    setIsAuth(bol: boolean) {
        this.isAuth = bol
    }

    requestAuthorization = async (payload: IRequestAuthData, onSuccess?: () => void, onError?: () => void) => {
        runInAction(() => this.loginLoading = true)
        try {
            const res = await instance().auth().authorization(payload)
            const loginInfo = res?.data?.response?.response
            const { access_token, access_token_refresh, user, roles } = loginInfo
            if (loginInfo) {
                localStorage.setItem(key.main, access_token)
                localStorage.setItem(key.refresh, access_token_refresh)
                localStorage.setItem('accountName', user.name)
                localStorage.setItem('roles', roles[0].name)
            }

            onSuccess && onSuccess()
        } catch (e) {
            /*checkError(e)*/
            onError && onError()
        }
        this.loginLoading = false
    }

    requestMyInfo = async () => {
        runInAction(() => this.myInfoLoading = true)
        try {
            const res = await instance().close().getMyInfo()
            this.myInfo = res.data?.data?.value
        } catch (e) {
            checkError(e)
        }
        runInAction(() => this.myInfoLoading = false)
    }

}

export const LoginPageStore = new LoginPage()