import React, { useEffect } from 'react'
import { Card, List, Space,  Tooltip } from 'antd';
import pdf from '../../../icons/pdficon.svg'
import { formatIsoToDate, formatIsoToTime } from '../../helpers/utils';

interface IMobileRaportVKProps {
    items : any
    // onItemClick : (element : any) => void
    loading: any
  }

export const MobileRaportVKProps: React.FC<IMobileRaportVKProps> = ({items, loading}) => {

  const titleCard = (name: string) => {
    return (
        <Tooltip trigger="hover" placement="topLeft" title={typeof name === 'string' ? name : ''}>
            {name ? name : '-'}
        </Tooltip>
    )
}

useEffect(() => {
    
})

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
     <List pagination={{ position: "both", pageSizeOptions: [25, 50, 75, 100], defaultPageSize: 25 }}
            dataSource = {items}
            loading = {loading}
            renderItem={(element: any) => (
                <Card style={{margin: '15px 0 15px 0'}} key={element.id} title={titleCard(element.objectName)} size='default'>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Система :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.system === 'string' ? element?.system : ''}>
                                    {element?.system ? element?.system.split('').length >= 20 ? `${element?.system.substr(0, 20)}...` : element?.system : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>ПИН :</div>
                            <div>
                                {element?.pult ? element?.pult : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Тип тревоги :</div>
                            <div>
                                {element?.alarmType ? element?.alarmType : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Наименование :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.objectName === 'string' ? element?.objectName : ''}>
                                    {element?.objectName ? element?.objectName.split('').length >= 20 ? `${element?.objectName.substr(0, 20)}...` : element?.objectName : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Адрес :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.objectAddress === 'string' ? element?.objectAddress : ''}>
                                    {element?.objectAddress ? element?.objectAddress.split('').length >= 20 ? `${element?.objectAddress.substr(0, 20)}...` : element?.objectAddress : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Принято :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.acceptButton === 'string' ? element?.acceptButton : ''}>
                                    {element?.acceptButton ? element?.acceptButton.split('').length >= 20 ? `${element?.acceptButton.substr(0, 20)}...` : element?.acceptButton : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Прибыл :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.arrivedButton === 'string' ? element?.arrivedButton : ''}>
                                    {element?.arrivedButton ? element?.arrivedButton.split('').length >= 20 ? `${element?.arrivedButton.substr(0, 20)}...` : element?.arrivedButton : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Убытие :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.departureButton === 'string' ? element?.departureButton : ''}>
                                    {element?.departureButton ? element?.departureButton.split('').length >= 20 ? `${element?.departureButton.substr(0, 20)}...` : element?.departureButton : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Отказ :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.cancleButton === 'string' ? element?.cancleButton : ''}>
                                    {element?.cancleButton ? element?.cancleButton.split('').length >= 20 ? `${element?.cancleButton.substr(0, 20)}...` : element?.cancleButton : '-'}
                                </Tooltip>
                            </div>
                        </div>
                        

                </Card>
            )}
            />
    </Space>
  )
}