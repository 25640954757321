import React, { FC, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { LaunchStore } from "../store/launch/launch";
import { Suspense } from "react";
import { ROUTE_PATH } from "./paths.main";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { Authorization } from "../components/Authorization/Authorization";
import { GoCountRaport } from "../components/GoCountRaport/GoCountRaport";
import { GoExternalObject } from "../components/GoExternalObject/GoExternalObject";
import { GoCountGroup } from "../components/GoCountGroup/GoCountGroup";
import { GoRaport } from "../components/GoRaport/GoRaport";
import { Dislocation } from "../components/Dislocation/Dislocation";
import { Raports } from "../components/Raports/Raports";
import { CaesarGet } from "../components/Caesar/CaesarGet";
import {GoCountChop} from "../components/GoCountChop/GoCountChop";
import { RaportsVK } from "../components/RaportsVK/RaportsVK";
import { RaportsAlert } from "../components/RaportAlert/RaportsAlert";
import { JournalActions } from "../components/JournalActions/JournalActions";
import { RaportsMonths } from "../components/RaportsMonths/RaportMonths";
import { OperatorRaports } from "../components/OperatorsRaport/OperatorsRaport";
import { Settings } from "../components/Settings/Settings";

export const RouterIndex: FC = ({ }) => {
       const { appLoading } = LaunchStore
       const Loader = <div>Загрузка...</div>
       if (appLoading) {
              return Loader
       }

       return (
              <BrowserRouter>
                     <Suspense fallback={Loader}>
                            <Switch>
                                   <Route exact
                                          path={'/'}
                                          component={() => <Redirect to={ROUTE_PATH.login} />}
                                   />
                                   <Route exact
                                          path={ROUTE_PATH.login}
                                          component={() => <Authorization />}
                                   />
                                   <Sidebar>
                                          <Route exact
                                                 path={ROUTE_PATH.goCountGroup}
                                                 component={() => <GoCountGroup />}

                                          />
                                          <Route exact
                                                 path={ROUTE_PATH.goExternalObject}
                                                 component={() => <GoExternalObject />}

                                          />
                                          <Route exact
                                                 path={ROUTE_PATH.goCountRaport}
                                                 component={() => <GoCountRaport />}

                                          />
                                          <Route exact
                                                 path={ROUTE_PATH.goRaport}
                                                 component={() => <GoRaport />}

                                          />
                                          <Route exact
                                                 path={ROUTE_PATH.dislocations}
                                                 component={() => <Dislocation />}

                                          />
                                          <Route exact
                                                 path={ROUTE_PATH.raports}
                                                 component={() => <Raports />}

                                          />

                                          <Route exact
                                                 path={ROUTE_PATH.caesar}
                                                 component={() => <CaesarGet />}

                                          />

                                          <Route exact
                                                 path={ROUTE_PATH.numberOfDepatures}
                                                 component={() => <GoCountChop />}

                                          />

                                          <Route exact
                                                 path={ROUTE_PATH.raportsVK}
                                                 component={() => <RaportsVK />}

                                          />

                                          <Route exact
                                                 path={ROUTE_PATH.raportsAlert}
                                                 component={() => <RaportsAlert/>}
                                          />

                                          <Route exact
                                                 path={ROUTE_PATH.journalActions}
                                                 component={() => <JournalActions/>}
                                          />
                                          <Route exact
                                                 path={ROUTE_PATH.raportsMonths}
                                                 component={() => <RaportsMonths/>}
                                          />
                                          <Route exact
                                                 path={ROUTE_PATH.operatorRaports}
                                                 component={() => <OperatorRaports/>}
                                          />
                                          <Route exact
                                                 path={ROUTE_PATH.settings}
                                                 component={() => <Settings/>}
                                          />

                                   </Sidebar>
                            </Switch>
                     </Suspense>
              </BrowserRouter>
       )
}