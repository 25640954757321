import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ReportsStore } from '../../store/reports/Reports'
import { TableFiltersBlock } from '../TableFiltersBlock/TableFiltersBlock'
import { IReportFilters } from '../../store/reports/types'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Fallback } from '../helpers/Fallback/Fallback'
import { NoData } from '../common/Nodata/NoData'
const s = require('./style.module.scss')

interface IGoCountGroupProps {

}

export const GoCountGroup: React.FC<IGoCountGroupProps> = observer(({ }) => {

    const {
        getGoCountGroup,
        goCountGroup,
        goCountGroupLoading
    } = ReportsStore

    const requestHandler = (payload: IReportFilters) => {
        getGoCountGroup(payload);
    }

    const scroll: { x?: number | string; y?: number | string } = { x: 0, y: 700 };
    const defaultTitle = () => 'Кол-во выездов (по группе)';

    interface DataType {
        key?: string | number
        time: string
        combat: string
        fake: string
        sum: string
    }

    const columns: ColumnsType<DataType> = goCountGroup?.headers.map((element: any, index: number) => {
        return ({
            key: element.name,
            title: element.name,
            dataIndex: element.mnemo,
        });
    }) || [];

    const dataSource: DataType[] = goCountGroup?.data
        ? goCountGroup.data.map((element: any, index: number) => {
            return {
                key: index,
                time: element?.time,
                combat: element?.combat,
                fake: element?.fake,
                sum: element?.sum,
            };
        })
        : [];

        const [activeTable, setActiveTable] = useState<boolean>(false)
    return <div className={s.container}>
        <div className={s.container__header}>
            <TableFiltersBlock flag={'goCountGroup'} xlsxFlag={'GroupWorkReport'} request={requestHandler} setActiveTable={setActiveTable} />
        </div>
        <div className={s.container__table}>
            {
                goCountGroup ?
                    <Table
                        size='large'
                        rowClassName={s.tableRow}
                        columns={columns}
                        dataSource={dataSource}
                        bordered
                        title={defaultTitle}
                        scroll={scroll}
                        loading={goCountGroupLoading}
                        pagination={false}
                    /> : goCountGroupLoading ? <Fallback/> :  <NoData />

            }
        </div>
    </div>
})