import React from "react";
import { makeAutoObservable, runInAction } from "mobx";
import { IApplicationList, ISidebarItem, ISidebarLoadings } from "./types.data";
import { ROUTE_PATH } from "../../routers/paths.main";
import { MyApplication, Calendar, Favorites, Workers, History } from '../../icons/icons';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { instance } from "../api";
import { IApplicationsCounts, TGetApplicationList } from "../api/bodies/types";
import { checkError } from "../api/helpers";

class SidebarMobx {

    pages: ISidebarItem[] = [

        // { id: '9', current: false, url: ROUTE_PATH.favourite, title: 'Избранное', icon: <Favorites /> },
        // { id: '10', current: false, url: ROUTE_PATH.calendar, title: 'Календарь', icon: <Calendar /> },
        // { id: '11', current: false, url: ROUTE_PATH.workers, title: 'Сотрудники', icon: <Workers /> },
        // { id: '12', current: false, url: ROUTE_PATH.history, title: 'История', icon: <History /> },
        // { id: '16', current: false, url: ROUTE_PATH.newApplication, title: 'Новая заявка' },
        // { id: '17', current: false, url: ROUTE_PATH.authorization, title: 'Авторизация' },
        // { id: '18', current: false, url: ROUTE_PATH.applicationInfo, title: 'Важная заявка' },
        // icon: <MyIcon/>
    ]

    applicationList: IApplicationList = {
        count: 1515,
        list: []
    }
    loadings: ISidebarLoadings = {
        applicationListLoading: false
    }

    applicationsCounts: IApplicationsCounts | null = null
    applicationsCountsLoading: boolean = false

    showSidebar: boolean = true

    constructor() {
        makeAutoObservable(this)
    }


    setCurrentPage = (newPage: ISidebarItem) => {
        runInAction(() => {
            this.pages = this.pages.map(page => ({
                ...page,
                current: page.id === newPage.id
            }))
        })
    }

    setShowSidebar(bol: boolean) {
        this.showSidebar = bol
    }

    requestApplicationsCounts = async () => {
        this.applicationsCountsLoading = true
        try {
            const res = { data: null }
            this.applicationsCounts = res.data
        } catch (e) {
            checkError(e)
        }
        this.applicationsCountsLoading = false
    }

    requestApplicationList = async (data: TGetApplicationList, onSuccess?: () => void, onError?: () => void) => {
        runInAction(() => this.loadings.applicationListLoading = true)
        try {
            const res = { data: null }
            onSuccess && onSuccess()
        } catch (e) {
            checkError(e)
            onError && onError()
        }
        this.loadings.applicationListLoading = false
    }
}

export const SidebarStore = new SidebarMobx()