import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ReportsStore } from '../../store/reports/Reports'
//import { Table } from '../common/Table/Table'
import { TableFiltersBlock } from '../TableFiltersBlock/TableFiltersBlock'
import { IRaport, IRaportGbrItem, IRaportSystemItem, IReportFilters } from '../../store/reports/types'
import { Dropdown, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table'
import { TEnumItem, formatIsoToDate, formatIsoToTime } from '../helpers/utils'
import { DownOutlined } from '@ant-design/icons';
import { Modal } from './component/Modal'
const s = require('./styles.module.scss')



interface ISettingsProps {

}

export const Settings: React.FC<ISettingsProps> = observer(({ }) => {

    const [openPopup, setOpenPopup] = React.useState<boolean>(false)
    const [clickedItem, setClickedItem] = React.useState<IRaport | null>(null)
    const [arrData, setArrayData] = useState([])
    const [openModal, setOpenModal] = useState({open: false, flag:''})
    const [territoryInfo, setTerritoryInfo] = useState()

    //Для таблицы
    const scroll: { x?: number | string; y?: number | string } = { x: 1000, y: 700 };
    const defaultTitle = () => 'Территории';
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(25)
    const [payload, setPayload] = useState<IReportFilters | null>(null)

    const {
        getReportXlSX,
        getSettings,
        settings,
        settingsLoading,
        deleteSettings,
        deleteSettingsLoading,
    } = ReportsStore

    const requestHandler = (payload: IReportFilters) => {
        setPage(1)
        setPayload(payload)
        getSettings('get', { ...payload, count : pageSize, offset: pageSize * (page - 1)});
    }

    useEffect(() => {
        if (!payload) {
            requestHandler({
                isExternal: false,
                isDeleted: false,
                count: 25,
                offset: 0,
                isWebReport : true
            });
        } else {
            getSettings('get',{ ...payload, count: pageSize, offset: pageSize * (page - 1)});
        }
    }, [page, pageSize])

    useEffect(() => {
        setPage(1)
    }, [pageSize])

    const onClickGetPdf = (id: number) => {
        getReportXlSX({
            "reportName": "SelectedRaportReport",
            "fileExtension": "pdf",
            "paramList": [
                {
                    "name": "request",
                    "value": {
                        "id": id
                    }
                }
            ]
        })
    }
    const items = [
        {
            key: '1',
            label: 'Редактировать',
            onClick: () => { setOpenModal({open: true, flag: 'post'}) },
        },
        {
            key: '2',
            label: 'Удалить',
            onClick: () => { setOpenModal({open: true, flag: 'delete'}) },
        },
    ];
    const columns = [
        {
            title: 'Территория',
            dataIndex: 'territoryName',
            key: 'territoryName',
            width: 100,
        },
        {
            title: 'ГБР',
            dataIndex: 'gbrs',
            key: 'gbrs',
            width: 100,
        },
    ]

    const getRows = () => {
        let rows:any[] = []
        settings?.map((item:any) => {
            let Row = {}
            let newRow: any = []
            item?.gbrs?.map((el: any)=>{
                newRow.push(el.gbrName)
                Object.assign(Row, {
                    "gbrs": newRow.join(', '),
                    "territoryName": item.territoryName,
                    "id": item.id
                })
            })
            rows.push(Row)
        })
        return rows
            }
            // const data = settings.map((element: any) => {
            //     return (
            //         {
            //             territoryName: element.territoryName,
            //             gbrs: element.gbrs
            //         }
            //     )
            // })

    const [activeTable, setActiveTable] = useState<boolean>(false)
    return (
    <><Modal
    title={openModal.flag} 
    flag={openModal.flag}
    open={openModal.open}
    closeHandler={() => setOpenModal({open: false, flag: ''})}
    territoryInfo={territoryInfo}
    arrData={settings}
    requestHandler={requestHandler}/>
    <div className={s.container}>
        <div className={s.container__header}>
            <TableFiltersBlock 
            flag={'settings'} 
            xlsxFlag={'settingsReport'} 
            request={requestHandler} 
            setActiveTable={setActiveTable} 
            buttonFlag={'none'} 
            setOpenModal={setOpenModal}
            settingsLoading={settingsLoading} />
        </div>
                <div className={s.container__table}>
                    <Table
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: () => {
                                    setTerritoryInfo(record)
                                }
                            }
                        }}
                        size='large'
                        rowClassName={s.tableRow}
                        columns={columns && [...columns.map(element => {
                            return({
                                ...element
                            })
                        }), {
                            title: 'Действия',
                            key: 'action',
                            align: 'center',
                            render: (_, record) => {
                                return (
                                    <Dropdown
                                        menu={{
                                            items,
                                        }}
                                    >
                                    <a className="text-primary text-decoration-none">
                                        Действия <DownOutlined />
                                    </a>
                                    </Dropdown>
                        )
                    },
                    width: 100
                        }]}
                        dataSource={getRows()}
                        bordered
                        title={defaultTitle}
                        scroll={scroll}
                        loading={settingsLoading}
                        pagination={false}
                    />
                </div>
    </div>
    </>
    )
})