import axios from "axios";
import { paths } from "./paths";
import { IRequestAuthData, TGetApplicationList } from "./bodies/types";

import { TPaginationReq } from "../../redux/types";
import { IGetReportXLSX, IReportFilters } from "../reports/types";
import { getInlineParams } from "./helpers";


export const key = {
    main: 'CLIENT_TOKEN',
    refresh: 'CLIENT_TOKEN_REFRESH',
    serviceUrl: process.env.REACT_APP_CLIENT_SERVICE_URL
        ? process.env.REACT_APP_CLIENT_SERVICE_URL
        : ''
}
const auth = () => axios.create({
    baseURL: paths.baseURL + '/' + 'api/',
    headers: {
        //mode: 'no-cors',
        ContentType: 'application/json'
    }
})

const refreshTok = (token: string | null) => axios.create({
    baseURL: paths.baseURL + '/' + 'api/',
    headers: {
        //mode: 'no-cors',
        Authorization: `Bearer ${token}`,
        ContentType: 'application/json'
    }
})



const instanceAxiosClose = (token: string | null, pult?: boolean) => axios.create({
    baseURL: paths.baseURL + '/' + (!!pult ? paths.baseURLClose.replace('data', 'pult') : paths.baseURLClose),
    headers: {
        //mode: 'no-cors',
        Authorization: `Bearer ${token}`,
        ContentType: 'application/json'
    }
})

const instanceAxiosCloseNew = (token: string | null, pult?: boolean) => axios.create({
    baseURL: paths.baseURL + '/' + paths.baseURLClose,
    headers: {
        //mode: 'no-cors',
        Authorization: `Bearer ${token}`,
        ContentType: 'application/json'
    }
})

const instanceAxiosCloseReport = (token: string | null, pult?: boolean) => axios.create({
    baseURL: paths.baseURL + '/' + paths.baseURLCloseReport,
    headers: {
        //mode: 'no-cors',
        Authorization: `Bearer ${token}`,
        ContentType: 'application/json'
    }
})
const instanceAxiosOpen = (token: string | null) => axios.create({
    baseURL: paths.baseURL + '/' + paths.baseURLOpen,
    headers: {
        //mode: 'no-cors',
        Authorization: `Bearer ${token}`,
        ContentType: 'application/json'
    }
})

export const instance = () => {
    const mainKey = key.main
    const token = localStorage.getItem(mainKey)
    const refreshToken = localStorage.getItem(key.refresh)

    return {
        auth: () => ({
            authorization: (data: IRequestAuthData) => auth().post(paths.authorization, data)
        }),
        close: () => ({

            getRefreshToken : () => refreshTok(refreshToken).get(paths.refreshToken, {}),
            //События
            getTaskActions: (body: any) => instanceAxiosClose(token).post(paths.getTaskActions, body),

            //Уведомления
            getMyNotifications: (offset: number, count: number) => instanceAxiosClose(token).post(paths.getMyNotifications, {
                offset,
                count
            }),
            checkedNotifications: () => instanceAxiosClose(token).post(paths.checkedNotifications, {}),


            //Сотрудники
            getWorkersListOrItem: (data: any) => instanceAxiosClose(token).post(paths.getWorkersListOrItem, data),
            iudGroup: (data: any) => instanceAxiosClose(token).post(paths.iudGroup, data),
            getWorkersWithGroups: (body: { flag?: string }) => instanceAxiosClose(token).post(paths.getWorkersWithGroups, body),
            getGroups: () => instanceAxiosClose(token).post(paths.getGroups, {}),
            addWorkersToGroup: (data: any) => instanceAxiosClose(token).post(paths.addWorkersToGroup, data),
            iudWorker: (body: any) => instanceAxiosClose(token).post(paths.iudWorker, body),

            //Информация о пользователе
            getMyInfo: () => instanceAxiosClose(token).post(paths.getMyInfo, {}),

            //формвью
            getFormview: (body: {}) => instanceAxiosClose(token).post(paths.getFormview, body),


            //Полный список ролей
            getFullRolesList: (body: {}) => instanceAxiosClose(token).post(paths.getFullRoleList, body),

            //Отчеты
            getGoCountRaport: (body: IReportFilters) => instanceAxiosClose(token).post(paths.getGoCountRaport, body),
            getGoExternalObject: (body: IReportFilters) => instanceAxiosClose(token).post(paths.getGoExternalObject, body),
            getGoCountGroup: (body: IReportFilters) => instanceAxiosClose(token).post(paths.getGoCountGroup, body),
            getGoRaport: (body: IReportFilters) => instanceAxiosClose(token).post(paths.getGoRaport, body),
            getDislocations: (body: IReportFilters) => instanceAxiosClose(token).post(paths.getDislocations, body),
            getRaports: (body: IReportFilters) => instanceAxiosClose(token).post(paths.getRaports, body),
            getRaportsVK: (body: IReportFilters) => instanceAxiosCloseNew(token).post(paths.getRaportsVK, body),
            getRaportsMonth: (body: IReportFilters) => instanceAxiosCloseNew(token).post(paths.getRaportsMonth, body),
            getRaportsMonthNew: (body: IReportFilters) => instanceAxiosCloseNew(token).post(paths.getRaportsMonthNew, body),
            getRaportsAlert: (body: IReportFilters) => instanceAxiosCloseNew(token).post(paths.getRaportsAlert, body),
            getJournalActions: (body: IReportFilters) => instanceAxiosCloseNew(token).post(paths.journalActions, body),
            getGBRS: () => instanceAxiosClose(token).get(paths.getGBRS),
            getPCO: () => instanceAxiosCloseNew(token).get(paths.getPCO),
            getEventTypes: () => instanceAxiosClose(token).get(paths.getEventTypes),
            getReportXLSX: (body: IGetReportXLSX) => instanceAxiosCloseReport(token).post(paths.getReportXLSX, body),
            getCaesarRaport : (body:IReportFilters) => instanceAxiosClose(token).post(paths.getCaesarReport, body),
            getChops : () => instanceAxiosClose(token).get(paths.getChopReport),
            sendChops : (body : IReportFilters) => instanceAxiosClose(token).post(paths.sendChopReport, body),
            getSettings: (body: any) => instanceAxiosCloseNew(token).get(paths.getTerritory + getInlineParams(body)),
            postSettings: (body: any) => instanceAxiosCloseNew(token).post(paths.updateTerritory, body),
            putSettings:(body: any) => instanceAxiosCloseNew(token).post(paths.addTerritory, body),
            deleteSettings: (body: any) => instanceAxiosCloseNew(token).get(paths.deleteTerritory + getInlineParams(body)),

            //Поиск рапорты
            getAddressesOfRaports : (body : {searchValue : string}) => instanceAxiosClose(token).post(paths.getAddressesOfRaports, body),
            getObjectsOfRaports : (body : {searchValue : string}) => instanceAxiosClose(token).post(paths.getObjectsOfRaports, body),
            getPultsOfRaports : (body : {searchValue : string}) => instanceAxiosClose(token).post(paths.getPultsOfRaports, body)
        })  
    }
}