import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ReportsStore } from '../../store/reports/Reports'
//import { Table } from '../common/Table/Table'
import { TableFiltersBlock } from '../TableFiltersBlock/TableFiltersBlock'
import { IRaport, IRaportGbrItem, IRaportMonth, IRaportSystemItem, IRaportVK, IReportFilters } from '../../store/reports/types'
// import { InfoModal } from './components/InfoModal'
import { List, Table, TableColumnsType, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table'
import { TEnumItem, formatIsoToDate, formatIsoToTime } from '../helpers/utils'
import { Fallback } from '../helpers/Fallback/Fallback'
import { NoData } from '../common/Nodata/NoData'
// import { MobileRaports } from './components/MobileRaports'
const s = require('./styles.module.scss')



interface IOperatorRaportsProps {

}

export const OperatorRaports: React.FC<IOperatorRaportsProps> = observer(({ }) => {

    const [openPopup, setOpenPopup] = React.useState<boolean>(false)
    const [clickedItem, setClickedItem] = React.useState<IRaportMonth | null>(null)
    const [arrData, setArrayData] = useState([])

    //Для таблицы
    const scroll: { x?: number | string; y?: number | string } = { x: 300, y: 700 };
    const defaultTitle = () => 'Обработка по месяцам';
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(25)
    const [payload, setPayload] = useState<IReportFilters | null>(null)



    // useEffect(() => {
    //     if (!payload) {
    //         requestHandler({
    //             isExternal: false,
    //             isDeleted: false,
    //             count: 25,
    //             offset: 0
    //         });
    //     } else {
    //         getReportMonth({ ...payload, count: pageSize, offset: pageSize * (page - 1)});
    //     }
    // }, [page, pageSize])

    useEffect(() => {
        setPage(1)
    }, [pageSize])


    const {
        getReportMonth,
        raportMonth,
        raportMonthLoading,
        getReportXlSX
    } = ReportsStore

    const requestHandler = (payload: IReportFilters) => {
        setPage(1)
        setPayload(payload)
        getReportMonth({ ...payload, count : pageSize, offset: pageSize * (page - 1)});
    }

    const onItemClick = (item: any) => {
        setClickedItem(item);
        setArrayData(raportMonth?.data)
        setOpenPopup(true);
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mobile, setMobile] = useState(windowWidth > 800 ? false : true)

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        windowWidth > 800 ? setMobile(false) : setMobile(true)
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [windowWidth, mobile]);

    interface DataType {
        "date": string,
        "day_time_events": {
            "internal_alarm": {
                "fake": {
                    "OS_count": string,
                    "TS_count": string,
                    "PS_count": string
                },
                "combat": {
                    "OS_count": string,
                    "TS_count": string,
                    "PS_count": string
                },
                "cancled": string
            },
            "external_alarm": {
                "completed": string,
                "cancled": string
            }
        },
        "night_time_events": {
            "internal_alarm": {
                "fake": {
                    "OS_count": string,
                    "TS_count": string,
                    "PS_count": string
                },
                "combat": {
                    "OS_count": string,
                    "TS_count": string,
                    "PS_count": string
                },
                "cancled": string
            },
            "external_alarm": {
                "completed": string,
                "cancled": string
            }
        },
        "total": string
                
    }
    const data = raportMonth?.data.all_month_selection.map((element: any) => {
        return (
            {
                date: element.date,
                day_time_events_fake_OS_count:element.day_time_events.internal_alarm.fake.OS_count,
                day_time_events_fake_TS_count:element.day_time_events.internal_alarm.fake.TS_count,
                day_time_events_fake_PS_count:element.day_time_events.internal_alarm.fake.PS_count,
                day_time_events_combat_OS_count:element.day_time_events.internal_alarm.combat.OS_count,
                day_time_events_combat_TS_count:element.day_time_events.internal_alarm.combat.TS_count,
                day_time_events_combat_PS_count:element.day_time_events.internal_alarm.combat.PS_count,
                day_time_events_cancled: element.day_time_events.internal_alarm.cancled,
                day_time_events_external_alarm_completed: element.day_time_events.external_alarm.completed,
                day_time_events_external_alarm_cancled: element.day_time_events.external_alarm.cancled,
                ///
                night_time_events_fake_OS_count:element.night_time_events.internal_alarm.fake.OS_count,
                night_time_events_fake_TS_count:element.night_time_events.internal_alarm.fake.TS_count,
                night_time_events_fake_PS_count:element.night_time_events.internal_alarm.fake.PS_count,
                night_time_events_combat_OS_count:element.night_time_events.internal_alarm.combat.OS_count,
                night_time_events_combat_TS_count:element.night_time_events.internal_alarm.combat.TS_count,
                night_time_events_combat_PS_count:element.night_time_events.internal_alarm.combat.PS_count,
                night_time_events_cancled: element.night_time_events.internal_alarm.cancled,
                night_time_events_external_alarm_completed: element.night_time_events.external_alarm.completed,
                night_time_events_external_alarm_cancled: element.night_time_events.external_alarm.cancled,
                total: element.total,
                all_total: <div style={{ fontWeight: '700' }}>{element.all_total}</div>
                ///
                // day_time_events: {
                //     internal_alarm: {
                //         fake: {
                //             OS_count: element.day_time_events.internal_alarm.fake.OS_count,
                //             TS_count: element.day_time_events.internal_alarm.fake.TS_count,
                //             PS_count: element.day_time_events.internal_alarm.fake.PS_count
                //         },
                //         combat: {
                //             OS_count: element.day_time_events.internal_alarm.combat.OS_count,
                //             TS_count: element.day_time_events.internal_alarm.combat.TS_count,
                //             PS_count: element.day_time_events.internal_alarm.combat.PS_count
                //         },
                //         cancled: element.day_time_events.internal_alarm.PS_count
                //     },
                //     external_alarm: {
                //         completed: element.day_time_events.external_alarm.completed,
                //         cancled: element.day_time_events.external_alarm.cancled
                //     }
                // },
                // night_time_events: {
                //     internal_alarm: {
                //         fake: {
                //             OS_count: element.night_time_events.internal_alarm.fake.OS_count,
                //             TS_count: element.night_time_events.internal_alarm.fake.TS_count,
                //             PS_count: element.night_time_events.internal_alarm.fake.PS_count
                //         },
                //         combat: {
                //             OS_count: element.night_time_events.internal_alarm.combat.OS_count,
                //             TS_count: element.night_time_events.internal_alarm.combat.TS_count,
                //             PS_count: element.night_time_events.internal_alarm.combat.PS_count
                //         },
                //         cancled: element.night_time_events.internal_alarm.PS_count
                //     },
                //     external_alarm: {
                //         completed: element.night_time_events.external_alarm.completed,
                //         cancled: element.night_time_events.external_alarm.cancled
                //     }
                // },
                // total: element.total
            }
        )
    })
    data?.push({date: <div style={{ fontWeight: '700' }}>{'Итого'}</div>, 
    day_time_events: <div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total}</div>,
    day_time_events_fake_OS_count:<div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.day_time_events.internal_alarm.fake.OS_count}</div>,
    day_time_events_fake_TS_count:<div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.day_time_events.internal_alarm.fake.TS_count}</div>,
    day_time_events_fake_PS_count:<div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.day_time_events.internal_alarm.fake.PS_count}</div>,
    day_time_events_combat_OS_count:<div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.day_time_events.internal_alarm.combat.OS_count}</div>,
    day_time_events_combat_TS_count:<div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.day_time_events.internal_alarm.combat.TS_count}</div>,
    day_time_events_combat_PS_count:<div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.day_time_events.internal_alarm.combat.PS_count}</div>,
    day_time_events_cancled: <div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.day_time_events.internal_alarm.cancled}</div>,
    day_time_events_external_alarm_completed: <div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.day_time_events.external_alarm.completed}</div>,
    day_time_events_external_alarm_cancled: <div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.day_time_events.external_alarm.cancled}</div>,
                ///
    night_time_events_fake_OS_count:<div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.night_time_events.internal_alarm.fake.OS_count}</div>,
    night_time_events_fake_TS_count:<div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.night_time_events.internal_alarm.fake.TS_count}</div>,
    night_time_events_fake_PS_count:<div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.night_time_events.internal_alarm.fake.PS_count}</div>,
    night_time_events_combat_OS_count:<div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.night_time_events.internal_alarm.combat.OS_count}</div>,
    night_time_events_combat_TS_count:<div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.night_time_events.internal_alarm.combat.TS_count}</div>,
    night_time_events_combat_PS_count:<div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.night_time_events.internal_alarm.combat.PS_count}</div>,
    night_time_events_cancled: <div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.night_time_events.internal_alarm.cancled}</div>,
    night_time_events_external_alarm_completed: <div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.night_time_events.external_alarm.completed}</div>,
    night_time_events_external_alarm_cancled: <div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.night_time_events.external_alarm.cancled}</div>,
    total: <div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.total}</div>,
    all_total: <div style={{ fontWeight: '700' }}>{raportMonth?.data.grand_total.all_total}</div>
    //element?.all_time_arrive_gbr_gbrs && element?.is_red_time_arrive_gbrs ? <div style={{ color: 'red' }}>{element?.all_time_arrive_gbr_gbrs}</div> : element?.all_time_arrive_gbr_gbrs
})
// typeof address === 'string' ? address : ''
    // const columns: ColumnsType<DataType> = raportMonth?.headers.map((element: any, key: number) => {
    //     return ({
    //         key: element.name,
    //         title: element.name,
    //         dataIndex: element.mnemo,
    //         children: element.children,
    //         ellipsis: {
    //             showTitle: false,
    //         },
    //         width: 180,
    //         render: (address: any, key: any) => {
    //             return (
    //                 <Tooltip trigger="hover" placement="topLeft" title={address}>
    //                     {typeof address === 'string' ? address : ''}
    //                 </Tooltip>
    //             )
    //         },
    //     });
    // }) || [];
    const columns: TableColumnsType<DataType> = [
        {
          title: 'ПЦО',
          dataIndex: 'pco',
          key: 'pco',
          width: 30,
          fixed: 'left',
        },
        {
            title: 'Оператор ФИО',
            dataIndex: 'FIO',
            key: 'FIO',
            width: 50,
            fixed: 'left',
          },
        {
          title: 'Создал тревог',
          children: [
            {
                title: '08:00 - 20:00',
                dataIndex: 'day_time_events_fake_OS_count',
                key: 'ОС',
                width: 70,
            },
            {
                title: '20:00 - 08:00',
                dataIndex: 'day_time_events_fake_TS_count',
                key: 'ТС',
                width: 70,
            },
        ]
        },
        {
            title: 'Завершил тревог',
            children: [
              {
                  title: '08:00 - 20:00',
                  dataIndex: 'day_time_events_fake_OS_count',
                  key: '08:00 - 20:00',
                  width: 70,
              },
              {
                  title: '20:00 - 08:00',
                  dataIndex: 'day_time_events_fake_TS_count',
                  key: '20:00 - 08:00',
                  width: 70,
              },
          ]
          },
          {
            title: 'Отменил тревог',
            children: [
              {
                  title: '08:00 - 20:00',
                  dataIndex: 'day_time_events_fake_OS_count',
                  key: '08:00 - 20:00',
                  width: 70,
              },
              {
                  title: '20:00 - 08:00',
                  dataIndex: 'day_time_events_fake_TS_count',
                  key: '20:00 - 08:00',
                  width: 70,
              },
          ]
          },
          {
            title: 'Завершил дислокацией',
            dataIndex: 'dislocation_complete',
            key: 'Завершил дислокацией',
            width: 70,
          }
        ]
   
    useEffect(() => {
        console.log(raportMonth?.data)
    }, [raportMonth])

    useEffect(() => {
        console.log(data)
    }, [data])

    const onClickGetPdf = (id: number) => {
        getReportXlSX({
            "reportName": "SelectedRaportReport",
            "fileExtension": "pdf",
            "paramList": [
                {
                    "name": "request",
                    "value": {
                        "id": id
                    }
                }
            ]
        })
    }


    const [activeTable, setActiveTable] = useState<boolean>(false)
    return <div className={s.container}>
        {/* {openPopup && <InfoModal
            closeHandler={() => setOpenPopup(false)}goCountGroup
            i={clickedItem}
            open={openPopup}
            arrData={arrData}
            title={`Рапорт по тревоге  - ${clickedItem?.object_name}`}
        />} */}
        <div className={s.container__header}>
            <TableFiltersBlock flag={'raportMonth'} xlsxFlag={'SelectionGroupByMonthReport'} request={requestHandler} setActiveTable={setActiveTable} />
        </div>
        <div className={s.smallTable}>
                    <Table
                        size='small'
                        rowClassName={s.tableRow}
                        columns={columns}
                        dataSource={data}
                        bordered
                        title={() => raportMonth?.data?.gbr_name}
                        scroll={scroll}
                        loading={raportMonthLoading}
                        pagination = {false}
                        onRow={(record, rowIndex) => {
                            return {
                                onDoubleClick: (event) => { onItemClick(record) },
                            };
                        }}
                    />
                    </div>
    </div>
})