import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ReportsStore } from '../../store/reports/Reports'
//import { Table } from '../common/Table/Table'
import { TableFiltersBlock } from '../TableFiltersBlock/TableFiltersBlock'
import { IRaport, IRaportGbrItem, IRaportSystemItem, IReportFilters } from '../../store/reports/types'
import { InfoModal } from './components/InfoModal'
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table'
import { TEnumItem, formatIsoToDate, formatIsoToTime } from '../helpers/utils'
import { MobileRaports } from './components/MobileRaports'
const s = require('./style.module.scss')



interface IRaportsProps {

}

export const Raports: React.FC<IRaportsProps> = observer(({ }) => {

    const [openPopup, setOpenPopup] = React.useState<boolean>(false)
    const [clickedItem, setClickedItem] = React.useState<IRaport | null>(null)
    const [arrData, setArrayData] = useState([])

    //Для таблицы
    const scroll: { x?: number | string; y?: number | string } = { x: 2300, y: 700 };
    const defaultTitle = () => 'Рапорты';
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(25)
    const [payload, setPayload] = useState<IReportFilters | null>(null)



    useEffect(() => {
        if (!payload) {
            requestHandler({
                isExternal: false,
                isDeleted: false,
                count: 25,
                offset: 0,
                isWebReport : true
            });
        } else {
            getNewReport({ ...payload, count: pageSize, offset: pageSize * (page - 1)});
        }
    }, [page, pageSize])

    useEffect(() => {
        setPage(1)
    }, [pageSize])


    const {
        getNewReport,
        newReportLoading,
        newReport,
        getReportXlSX
    } = ReportsStore

    const requestHandler = (payload: IReportFilters) => {
        setPage(1)
        setPayload(payload)
        getNewReport({ ...payload, count : pageSize, offset: pageSize * (page - 1)});
    }

    const onItemClick = (item: IRaport) => {
        setClickedItem(item);
        setArrayData(newReport?.data)
        setOpenPopup(true);
        console.log(item)
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mobile, setMobile] = useState(windowWidth > 800 ? false : true)

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        windowWidth > 800 ? setMobile(false) : setMobile(true)
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [windowWidth, mobile]);

    interface DataType {
        key: string;
        name: string;
        "id"?: number,
        "pult"?: string,
        "is_external"?: boolean,
        "gbrs"?: IRaportGbrItem[],
        "time_call_gbr"?: string,
        "object_name"?: string,
        "dislocation_type"?: TEnumItem,
        "object_address"?: string,
        "pco"?: TEnumItem,
        "operator_sent"?: string,
        "operator_taked"?: string,
        "event_type"?: TEnumItem,
        "system"?: IRaportSystemItem,
        "report_comment"?: string,
        "reason"?: TEnumItem,
        "created_at"?: string,
        "updated_at"?: string
        "time_departure_gbr"?: string
        "time_work"?: string
        "time_in"?: string
        "external_state"?: string
        "alert_type"?: string
        "operator_closed_full_name"?: string
    }
// typeof address === 'string' ? address : ''
    const columns: ColumnsType<DataType> = newReport?.headers.map((element: any, key: number) => {
        return ({
            key: element.name,
            title: element.name,
            dataIndex: element.mnemo,
            ellipsis: {
                showTitle: true,
            },
            width: 180,
            // render: (address: any, key: any) => {
            //     return (
            //         <Tooltip trigger="hover" placement="topLeft" title={address}>
            //             {/* {address} */}
            //         </Tooltip>
            //     )
            // },
        });
    }) || [];
   

    const data: DataType[] = newReport?.data.map((element: any) => {
        return (
            {
                key: element?.id,
                created_at: element?.created_at && formatIsoToDate(element?.created_at) + ' ' + formatIsoToTime(element?.created_at),
                pco: element?.pco?.name,
                system: element?.system?.name,
                pult: element?.pult,
                object_name: element?.object_name,
                object_address: element?.object_address,
                external_state: element?.external_state,
                time_work: element?.time_work && formatIsoToDate(element?.time_work) + ` ` + formatIsoToTime(element?.time_work),
                time_in: element?.time_in && formatIsoToDate(element?.time_in) + ` ` + formatIsoToTime(element?.time_in),
                time_call_gbr: element?.time_call_gbr && formatIsoToDate(element?.time_call_gbr) + ` ` + formatIsoToTime(element?.time_call_gbr),
                all_name_gbrs: element?.all_name_gbrs,
                all_time_accept_gbr: element?.all_time_accept_gbr,
                all_time_departure_gbr: element?.all_time_departure_gbr,
                all_time_arrive_gbr_gbrs: element?.all_time_arrive_gbr_gbrs && element?.is_red_time_arrive_gbrs ? <div style={{ color: 'red' }}>{element?.all_time_arrive_gbr_gbrs}</div> : element?.all_time_arrive_gbr_gbrs,
                date_close_event: element?.date_close_event && formatIsoToDate(element?.date_close_event) + ` ` + formatIsoToTime(element?.date_close_event),
                time_departure_gbr: element?.time_departure_gbr && formatIsoToDate(element?.time_departure_gbr) + ` ` + formatIsoToTime(element?.time_departure_gbr),
                event_type: element?.event_type?.name,
                alert_type: element?.alert_type,
                dislocation_type: element?.dislocation_type,
                reason_name: element?.reason_name,
                operator_taked: element?.operator_taked,
                operator_sent: element?.operator_sent,
                operator_closed_full_name: element?.operator_closed_full_name,
                report_comment: element?.report_comment,
                chief: element?.chief,
                chop_full_name: element?.chop_full_name
            }
        )
    })

    const onClickGetPdf = (id: number) => {
        getReportXlSX({
            "reportName": "SelectedRaportReport",
            "fileExtension": "pdf",
            "paramList": [
                {
                    "name": "request",
                    "value": {
                        "id": id
                    }
                }
            ]
        })
    }


    const [activeTable, setActiveTable] = useState<boolean>(false)
    return <div className={s.container}>
        {openPopup && <InfoModal
            closeHandler={() => setOpenPopup(false)}
            i={clickedItem}
            open={openPopup}
            arrData={arrData}
            title={`Рапорт по тревоге  - ${clickedItem?.object_name}`}
        />}
        <div className={s.container__header}>
            <TableFiltersBlock flag={'raports'} xlsxFlag={'RaportsReport' || 'ExternalRaportsReport'} request={requestHandler} setActiveTable={setActiveTable} />
        </div>
        {
            mobile ? <MobileRaports
                onItemClick={onItemClick}
                onClickGetPdf={onClickGetPdf}
                items={newReport?.data}
                loading={newReportLoading}
            />
                :
                <div className={s.container__table}>
                    <Table
                        size='large'
                        rowClassName={s.tableRow}
                        columns={columns}
                        dataSource={data}
                        bordered
                        title={defaultTitle}
                        scroll={scroll}
                        loading={newReportLoading}
                        pagination={{
                            position: ['topLeft', 'bottomLeft'],
                            pageSizeOptions: [25, 50, 75, 100],
                            defaultPageSize: pageSize,
                            onChange: (page, pageSize) => {
                                setPage(page)
                                setPageSize(pageSize)
                            },
                            defaultCurrent : page,
                            total : newReport?.total,
                            current : page
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                onDoubleClick: (event) => { onItemClick(record) },
                            };
                        }}
                    />
                </div>
        }
    </div>
})