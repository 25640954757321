import {applyMiddleware, combineReducers, createStore} from "redux";
import createSagaMiddleware from "redux-saga";
import thunkMiddleware from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
import {rootSaga} from "./rootSaga";
import {authReducer} from "./reducers/auth";
import {TAuthReducer} from "./reducers/auth/types";

const initialState = {}

const combined = combineReducers({
    authReducer: authReducer,
})

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware, thunkMiddleware]
const store = createStore(
    combined,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
)

export type TRootState = {
    authReducer: TAuthReducer
}

sagaMiddleware.run(rootSaga)

export {store}