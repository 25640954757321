import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ReportsStore } from '../../store/reports/Reports'
import { TableFiltersBlock } from '../TableFiltersBlock/TableFiltersBlock'
import { IReportFilters } from '../../store/reports/types'
import { Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { formatIsoToDate } from '../helpers/utils'
import { MobileGoExternalObjects } from './component/MobileGoExternalObjects'
const s = require('./style.module.scss')

interface IGoExternalObjectProps {

}

export const GoExternalObject: React.FC<IGoExternalObjectProps> = observer(({ }) => {
    const {
        getGoExternalObject,
        goExternalObject,
        goExternalObjectLoading
    } = ReportsStore

    const requestHandler = (payload: IReportFilters) => {
        getGoExternalObject(payload);
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mobile, setMobile] = useState(windowWidth > 800 ? false : true)

    useEffect(() => {
        requestHandler({})
    }, [])

    //Для таблицы
    const scroll: { x?: number | string; y?: number | string } = { x: 1000, y: 700 };
    const defaultTitle = () => 'Выезды на объекты (ст.орг.)';


    interface DataType {
        key?: string | number
        pult: string
        name: string
        address: string
        fake: string
        combat: string
        count: string
        chop_full_name: string
        contract_number: string
        contract_date: string
        payment_option: string
    }

    const columns: ColumnsType<DataType> = goExternalObject?.headers.map((element: any, index: number) => {
        return ({
            key: element.name,
            title: element.name,
            dataIndex: element.mnemo,
            ellipsis: {
                showTitle: false,
            },
            width: 180,
            render: (address: any) => {
                return (
                    <Tooltip trigger="hover" placement="topLeft" title={typeof address === 'string' ? address : ''}>
                        {typeof address === 'string' ? address : ''}
                    </Tooltip>
                )
            },
        });
    }) || [];

    const dataSource: DataType[] = goExternalObject?.data
        ? goExternalObject.data.map((element: any, index: number) => {
            return {
                key: element.id,
                pult: element?.pult,
                name: element?.name,
                address: element?.address,
                fake: element?.fake,
                combat: element?.combat,
                count: element?.count,
                chop_full_name: element?.chop_full_name,
                contract_number: element?.contract_number,
                contract_date: element?.contract_date,
                payment_option: element?.payment_option,
            };
        })
        : [];


        const [activeTable, setActiveTable] = useState<boolean>(false)
    return <div className={s.container}>
        <div className={s.container__header}>
            <TableFiltersBlock xlsxFlag={'ExternalDepartureCountReport'} request={requestHandler} flag={'ExternalDepartureCount'} setActiveTable={setActiveTable} />
        </div>
        <div className={s.container__table}>
            {mobile ? 
            <MobileGoExternalObjects
            items={goExternalObject?.data}
            loading={goExternalObjectLoading}/>
            :
            <Table
                size='large'
                rowClassName={s.tableRow}
                columns={columns}
                dataSource={dataSource}
                bordered
                title={defaultTitle}
                scroll={scroll}
                loading={goExternalObjectLoading}
                pagination={{ position: ['topLeft', 'bottomLeft'], pageSizeOptions: [25, 50, 75, 100], defaultPageSize: 25 }}
            />}
            
        </div>
    </div>
})